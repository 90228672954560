.sidebar{
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    gap: 20px;
    width: 100%;
    max-width: 250px;
    height: 100vh;
    background: white;
}
.sidebar-item{
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0px 20px;
    padding: 5px 10px;
    border-radius: 6px;
    background: #F6F6F6;
    gap: 20px;
    cursor: pointer;
}
@media(max-width:800px){
    .sidebar{
        padding: 30px 0px;
        flex-direction: row;
        width: 100%;
        max-width: none;
        height: auto;
        justify-content: center;
    }
    .sidebar-item{
        margin:0;
    }
}